import React from 'react';
import technical from '../images/technical.svg'; 
import vnet from '../images/vnet.svg';
import hosting from '../images/hosting.svg';
import consultation from '../images/consultation.svg';

const Services = () => {

    return (
        <div className="bg-gray-100 py-12" id='services' >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">服务</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">我们提供多种专业的服务供您的企业选择</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={hosting} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">服务器部署</h2>
                                    <p className="text-md font-medium">
                                        不论是简单的文件服务、域控制器、复杂权限设置、还是需要服务器冗余以及服务器的文件安全，我们都可以做到。
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={vnet} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">虚拟网络</h2>
                                    <p className="text-md font-medium">
                                        在生产力日益发达的现代，居家还有远程办公、跨厂区协同工作变得更加趋势化，SSLVPN、Tailscale（包括Derp），WireGuard助力您的企业打破传统网络带来的工作壁垒。
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={consultation} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">会议系统</h2>
                                    <p className="text-md font-medium">
                                        会议是一家企业工作中很重要的节点，很多灵感和创意均在会议上涌现，如何高效记录会议内容，我们来替您解决。
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={technical} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">技术咨询</h2>
                                    <p className="text-md font-medium">
                                        您的企业是否遇到了各种技术问题无法解答，我们提供专业的技术咨询和解决方案。
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;