import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import mujinband from '../images/mujinband.svg';
import weixin from '../images/WeChat.svg';

const About = () => {
    return (
        <>
            <div>
                <NavBar />
            </div>
                <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 " id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="Mujin Band" className="rounded-t float-right" src={mujinband} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-blue-900 font-bold">苏州木今电子科技有限公司</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>木今电子科技成立于2024年，是一家专注于中小企业网络运维，提供技术支持和咨询的专业公司。</p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>欢迎您的企业选择我们为您提供专业的IT服务。</p>
                            </div>

                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>您可以添加以下微信向我们咨询详情：</p>
                                <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                                    <img alt="qrcode" className="rounded-t float-right w-24 h-24" src={weixin} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
        </>
    )
}

export default About;